import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import CameraEnhanceIcon from "@material-ui/icons/CameraEnhance";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontFamily: "Allura",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
}));

export default function ControlledAccordions({ opciones, handleShowPlate }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {opciones.map((a) => (
        <Accordion
          key={`${a.id}-key`}
          expanded={expanded === `panel${a.id}`}
          onChange={handleChange(`panel${a.id}`)}
          style={{backgroundColor:"#000", color:"#fff", border:"solid 1px #fff"}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls={`panel${a.id}bh-}content`}
            id={`panel${a.id}bh-header`}
          >
            <Typography className={classes.heading}>{a.title}</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            {a.rack.map((items) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "0.8rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {items.photo && (
                    <Box onClick={() => handleShowPlate(items.photo)}>
                      <CameraEnhanceIcon />
                    </Box>
                  )}
                  {items.name}
                </Typography>
                <hr
                  style={{
                    width: "65%",
                    border: 0,
                    borderBottom: "1px dotted lightgrey",
                  }}
                />
                <Typography
                  style={{ fontSize: "0.8rem", whiteSpace: "nowrap" }}
                >
                  {items.value}
                </Typography>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
