import styles from "./styles/Home.module.css";
import Header from "../src/components/header";
import IconLabelTabs from "./components/tabs";
import Footer from "./components/footer";

export default function App() {
  return (
    <div className={styles.container}>
      <Header />
      <IconLabelTabs />
      <Footer/>
    </div>
  );
}
