import React, { useState } from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ControlledAccordions from "../accordion";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import Paper from "@material-ui/core/Paper";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import menu from "../../Assets/menu.json";
import bebida from "../../Assets/bebida.json";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 500,
    backgroundColor: "#000",
    color:"#fff"
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default function IconLabelTabs() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [showPlate, setShowPlate] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="inherit"
        aria-label="icon label tabs example"
      >
        <Tab icon={<RestaurantMenuIcon />} label="MENU" {...a11yProps(0)} />
        <Tab icon={<LocalBarIcon />} label="BEBIDAS" {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <ControlledAccordions opciones={menu} handleShowPlate={setShowPlate} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ControlledAccordions opciones={bebida} />
      </TabPanel>

      <Dialog
        onClose={() => {
          setShowPlate(false);
        }}
        aria-labelledby="simple-dialog-title"
        open={showPlate && true}
      >
        <img
          src={process.env.PUBLIC_URL + showPlate}
          alt="plato"
          width="100%"
          height="100%"
          ismap="true"
        />
      </Dialog>
    </Paper>
  );
}
